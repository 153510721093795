const productData = [
  {
    id: 1,
    name: "iPhone 14 128GB - Midnight - Unlocked - Dual eSIM",
    main: "FPP100 Printed Ballphone",
    reviews: "290",
    description: "",
    price: ["475.69", "475.90", "483.66", "539.35"],
    prePrice: "729.00",
    less: "",
    appearance: ["Fair", "Good", "Excellent", "Premium"],
    color: ["Midnight", "Red", "Yellow", "Blue", "Purple", "Starlight"],
    bacColor: [
      "rgb(24, 32, 40)",
      "rgb(255, 0, 0)",
      "rgb(255, 255, 0)",
      "rgb(156, 176, 196)",
      "rgb(230, 218, 230)",
      "rgb(238, 233, 229)",
    ],
    images: [
      "/phone/1001.jpg",
      "/phone/1001a.jpg",
      "/phone/1001b.jpg",
      "/phone/1001c.jpg",
      "/phone/1001d.jpg",
      "/phone/1001e.jpg",
      "/phone/1001f.png",
      "/phone/1001g.png",
    ],
  },
  {
    id: 2,
    name: "MacBook Air (2022) 13.6-inch - Apple M2 8-core and 8-core GPU - 8GB RAM - SSD 256GB",
    main: "MacBook Air 13' (2020) - QWERTY - English",
    reviews: "156",
    description:
      "Apple M1 8-core - 7-core GPU - SSD 256 GB - RAM 8GB - Space Gray - QWERTY - English",
    price: ["788.22", "851.72", "809.13"],
    prePrice: "999.00",
    less: "353.98",
    appearance: ["Fair", "Good", "Excellent"],
    color: ["Space Gray", "Silver", "Midnight", "Starlight"],
    bacColor: [
      "rgb(24, 32, 40)",
      "rgb(216, 216, 216)",
      "rgb(24, 32, 40)",
      "rgb(238, 233, 229)",
    ],
    images: [
      "/phone/1002.jpg",
      "/phone/1002a.jpg",
      "/phone/1002b.jpg",
      "/phone/1002c.jpg",
      "/phone/1002d.jpg",
    ],
  },
  {
    id: 3,
    name: "iPad Air (2022) 64GB - Space Gray - (WiFi)",
    main: "iPad Air (2022) - WiFi",
    reviews: "33",
    description: "64 GB - Space Gray",
    price: ["400.26", "414.65", "412.49"],
    prePrice: "599.00",
    less: "260.26",
    appearance: ["Fair", "Good", "Excellent"],
    color: ["Space Gray", "Blue", "Starlight", "Purple", "Pink"],
    bacColor: [
      "rgb(24, 32, 40)",
      "rgb(77, 173, 226)",
      "rgb(238, 233, 229)",
      "rgb(221, 210, 239)",
      "#FCE7E7",
    ],
    images: ["/phone/1003.jpg", "/phone/1003a.jpg"],
  },
  {
    id: 4,
    name: "Switch OLED 64GB - White",
    main: "Switch OLED",
    reviews: "35",
    description: "64 GB - White",
    price: ["269.00", "280.00"],
    prePrice: "999.00",
    less: "320.00",
    appearance: ["Good", "Excellent"],
    color: ["Black", "White"],
    bacColor: ["rgb(29, 29, 29)", "rgb(243, 243, 243)"],
    images: ["/phone/1004.jpg"],
  },
  {
    id: 5,
    name: "iPhone 12 64GB - Black - Unlocked",
    main: "iPhone 12 - Unlocked",
    reviews: "1331",
    description: "64 GB - Black",
    price: ["236.99", "269.09", "280.72", "363.99"],
    prePrice: "629.00",
    less: "",
    appearance: ["Fair", "Good", "Excellent", "Premium"],
    color: ["Black", "Red", "Blue", "Green", "Purple", "White"],
    bacColor: [
      "rgb(0, 0, 0)",
      "rgb(255, 0, 0)",
      "rgb(156, 176, 196)",
      "rgb(217, 239, 213)",
      "rgb(230, 218, 230)",
      "rgb(255, 255, 255)",
    ],
    images: [
      "/phone/1005.jpg",
      "/phone/1005a.jpg",
      "/phone/1005b.jpg",
      "/phone/1005c.jpg",
      "/phone/1005d.jpg",
      "/phone/1005e.jpg",
    ],
  },
  {
    id: 6,
    name: "iPad Air M2 11 (2024) 256GB - Blue - (WiFi)",
    main: "iPad Air 2024",
    reviews: "11",
    description: "Apple latest release",
    price: ["659.00"],
    prePrice: "699.00",
    list: [
      "Size: 11' & 13'",
      "Liquid Retina display",
      "M2 Chip",
      "12MP Wide camera",
      "10MP Ultra Wide Camera: No",
      "4K video",
      "Touch ID",
      "Landscapes stereo speakers",
      "2 microphones",
    ],
    less: "519.00",
    appearance: ["Excellent"],
    color: ["Space Gray", "Blue"],
    bacColor: ["rgb(24, 32, 40)", "rgb(156, 176, 196)"],
    images: [
      "/phone/1006.jpg",
      "/phone/1006a.jpg",
      "/phone/1006b.jpg",
      "/phone/1006c.jpg",
      "/phone/1006d.jpg",
    ],
  },
  {
    id: 7,
    name: "iPad Pro 11 (2022) 128GB - Space Gray - (WiFi)",
    main: "iPad Pro 2022",
    reviews: "11",
    description: "Available on Back Market",
    price: ["709.00", "739.00", "739.00 "],
    prePrice: "799.00",
    list: [
      "Size: 11' & 12.9'",
      "Liquid Retina Display",
      "M2 Chip",
      "12MP Wide camera",
      "10MP Ultra Wide Camera: Yes",
      "4K video, ProRes",
      "Face ID",
      "4 speakers audio",
      "5 studio-quality microphones",
    ],
    less: "569.00",
    appearance: ["Good", "Fair", "Excellent"],
    color: ["Space Gray", "Silver"],
    bacColor: ["rgb(24, 32, 40)", "rgb(216, 216, 216)"],
    images: [
      "/phone/1007.jpg",
      "/phone/1007a.jpg",
      "/phone/1007b.jpg",
      "/phone/1007c.jpg",
      "/phone/1007d.jpg",
      "/phone/1007e.jpg",
    ],
  },
  {
    id: 8,
    name: "iPad Air (2022) 64GB - Space Gray - (WiFi)",
    main: "iPad Air 2022",
    reviews: "34",
    description: "Available on Back Market",
    price: ["400.26", "414.65", "412.49"],
    prePrice: "599.00",
    list: [
      "Size 10.9'",
      "Liquid Retina Display",
      "M1 Chip",
      "12MP Wide camera",
      "10MP Ultra Wide Camera: No",
      "4K video",
      "Touch ID in top button",
      "Landscape stereo speakers",
      "2 microphones",
    ],
    less: "260.26",
    appearance: ["Fair", "Good", "Excellent"],
    color: ["Space Gray", "Blue", "Starlight", "Purple", "Pink"],
    bacColor: [
      "rgb(24, 32, 40)",
      "rgb(156, 176, 196)",
      "rgb(238, 233, 229)",
      "rgb(230, 218, 230)",
      "#FCE7E7",
    ],
    images: [
      "/phone/1008.jpg",
      "/phone/1008a.jpg",
      "/phone/1008b.jpg",
      "/phone/1008c.jpg",
      "/phone/1008d.jpg",
    ],
  },
  {
    id: 9,
    name: "iPad 10.2 (2019) 32GB - Space Gray - (WiFi)",
    main: "iPad 10.2 (2019) - WiFi",
    reviews: "253",
    description: "32 GB - Space Gray",
    price: ["121.62", "126.10", "155.20"],
    prePrice: "329.00",
    less: "0.00",
    appearance: ["Fair", "Good", "Excellent"],
    color: ["Space Gray", "Silver", "Gold"],
    bacColor: ["#52514F", "#D8D8D8", "#F6D8C1"],
    images: [
      "/phone/1009.jpg",
      "/phone/1009a.jpg",
      "/phone/1009b.jpg",
      "/phone/1009c.jpg",
      "/phone/1009d.jpg",
      "/phone/1009e.jpg",
    ],
  },
  {
    id: 10,
    name: "iPhone 12 64GB - Black - Unlocked",
    main: "iPhone 12 - Unlocked",
    reviews: "As low as £5.22 each",
    description: "Bring home a smarter phone",
    price: ["252.68", "269.09", "269.69", "363.99"],
    prePrice: "999.00",
    less: "1311",
    appearance: ["Fair", "Good", "Excellent", "Premium"],
    color: ["Black", "Red", "Blue", "Green", "Purple", "White"],
    bacColor: [
      "rgb(0, 0, 0)",
      "rgb(255, 0, 0)",
      "rgb(156, 176, 196)",
      "rgb(217, 239, 213)",
      "rgb(230, 218, 230)",
      "rgb(255, 255, 255)",
    ],
    images: [
      "/phone/10010.jpg",
      "/phone/10010a.jpg",
      "/phone/10010b.jpg",
      "/phone/10010c.jpg",
      "/phone/10010d.jpg",
      "/phone/10010e.jpg",
    ],
  },
  {
    id: 11,
    name: "MacBook Air 13.3-inch (2017) - Core i5 - 8GB - SSD 512GB",
    main: "MacBook",
    reviews: "280",
    description: "All of the buttons, all of the savings",
    price: ["243.99", "261.30", "366.00"],
    prePrice: "999.00",
    less: "103.99",
    appearance: ["Fair", "Good", "Excellent"],
    color: ["Aluminium"],
    bacColor: ["rgb(245, 245, 245)"],
    images: [
      "/phone/10011.jpg",
      "/phone/10011a.jpg",
      "/phone/10011b.jpg",
      "/phone/10011c.jpg",
      "/phone/10011d.jpg",
      "/phone/10011e.jpg",
    ],
  },
  {
    id: 12,
    name: "PlayStation 5 1000GB - White",
    main: "Gaming consoles",
    reviews: "58",
    description: "From A to X",
    price: ["429.00", "425.32", "460.00"],
    prePrice: "499.00",
    less: "320.00",
    appearance: ["Fair", "Good", "Excellent"],
    color: ["White"],
    bacColor: ["#F3F3F3"],
    images: ["/phone/10012.jpg", "/phone/10012a.jpg", "/phone/10012b.jpg"],
  },
  {
    id: 13,
    name: "MacBook Pro Retina 16-inch (2019) - Core i7 - 32GB - SSD 512GB",
    main: "Chromebooks & Laptops",
    reviews: "238",
    description: "Premium laptops without the premium prices",
    price: ["622.99", "799.00", "758.00"],
    prePrice: "3599.00",
    less: "659.00",
    appearance: ["Fair", "Good", "Excellent"],
    color: ["Space Gray", "Silver"],
    bacColor: ["rgb(24, 32, 40)", "rgb(216, 216, 216)"],
    images: [
      "/phone/10013.jpg",
      "/phone/10013a.jpg",
      "/phone/10013b.jpg",
      "/phone/10013c.jpg",
      "/phone/10013d.jpg",
      "/phone/10013e.jpg",
    ],
  },
  {
    id: 14,
    name: "Apple AirPods 2nd gen (2019) - Lightning Charging case",
    main: "AirPods and Earphones",
    reviews: "190",
    description: "Sustainably sourced audio",
    price: ["65.40", "64.00", "71.79"],
    prePrice: "999.00",
    less: "0.00",
    appearance: ["Fair", "Good", "Excellent"],
    color: ["White"],
    bacColor: ["rgb(255, 255, 255)"],
    images: [
      "/phone/10014.jpg",
      "/phone/10014a.jpg",
      "/phone/10014b.jpg",
      "/phone/10014c.jpg",
      "/phone/10014d.jpg",
      "/phone/10014e.jpg",
      "/phone/10014f.jpg",
    ],
  },
  {
    id: 15,
    name: "Apple Watch Series 5 (2019) GPS + Cellular 44 mm - Aluminium Space Gray - Sport band Black",
    main: "Apple Watch",
    reviews: "48",
    description: "Talk to your watch",
    price: ["99.74", "256.00", "169.00"],
    prePrice: "999.00",
    less: "0.00",
    appearance: ["Fair", "Good", "Excellent"],
    color: ["Black"],
    bacColor: ["rgb(0, 0, 0)"],
    images: ["/phone/10015.jpg", "/phone/10015a.jpg", "/phone/10015b.jpg"],
  },
  {
    id: 16,
    name: "Galaxy S21 5G 128GB - Gray - Unlocked",
    main: "Samsung Galaxy",
    reviews: "458",
    description: "Explore the vast universe beyond Apple",
    price: ["157.49", "173.42", "189.10", "242.06"],
    prePrice: "999.00",
    less: "",
    appearance: ["Fair", "Good", "Excellent", "Premium"],
    color: ["Black", "Gray", "Purple", "Pink", "White"],
    bacColor: ["rgb(0, 0, 0)", "#C0C0C0", "#E6DAE6", "#FCE7E7", "#FFFFFF"],
    images: ["/phone/10016b.jpg", "/phone/10016a.jpg", "/phone/10016.jpg"],
  },
  {
    id: 17,
    name: "iMac 24-inch Retina (Early 2021) M1 3.2GHz - SSD 512 GB - 16GB",
    main: "iMac",
    reviews: "1",
    description: "Welcome to the big screen",
    price: ["899.97", "899.97", "950.00"],
    prePrice: "999.00",
    less: "759.97",
    appearance: ["Fair", "Good", "Excellent"],
    color: ["Silver", "Purple", "Blue"],
    bacColor: ["rgb(216, 216, 216)", "rgb(221, 210, 239)", "rgb(77, 173, 226)"],
    images: ["/phone/10017.jpg", "/phone/10017a.jpg", "/phone/10017b.jpg"],
  },
  {
    id: 18,
    name: "Sennheiser HD 450BT Noise cancelling Headphone Bluetooth with microphone - Black",
    main: "Bestsellers",
    reviews: "3",
    description: "Better than ever, and up to 70% off",
    price: ["129.00", "127.71", "69.95"],
    prePrice: "129.95",
    less: "",
    appearance: ["Fair", "Good", "Excellent"],
    color: ["Black"],
    bacColor: ["rgb(0,0,0)"],
    images: ["/phone/10018.jpg", "/phone/10018a.jpg", "/phone/10018b.jpg"],
  },
  {
    id: 19,
    name: "HYL100 Highlighter - Printed Multi-Colour Highlighters",
    main: "HYL100 Printed Highlighter",
    reviews: "As low as £0.90 each",
    description:
      "A hand-shaped highlighter with five popular coloured inks and a large print area. Each 'finger' is a separate highlighter phone with a different coloured ink.",
    price: ["788.22", "851.72", "809.13"],
    prePrice: "999.00",
    less: "",
    appearance: ["Fair", "Good", "Excellent"],
    color: ["Space Gray", "Silver", "Midnight", "Starlight"],
    bacColor: ["rgb(24, 32, 40)", "rgb(216, 216, 216)"],
    images: [
      "/phone/1002.jpg",
      "/phone/1002a.jpg",
      "/phone/1002b.jpg",
      "/phone/1002c.jpg",
      "/phone/1002d.jpg",
    ],
  },
  {
    id: 20,
    name: "RUL100 Ruler - Printed 30cm Promotional Rulers",
    main: "RUL100 Printed Ruler",
    reviews: "As low as £0.76 each",
    description:
      "A 30cm solid plastic ruler with centimetres on one side and inches on the other. Available in Clear or White with a large digital print area.",
    price: ["788.22", "851.72", "809.13"],
    prePrice: "999.00",
    less: "",
    appearance: ["Fair", "Good", "Excellent"],
    color: ["Space Gray", "Silver", "Midnight", "Starlight"],
    bacColor: ["rgb(24, 32, 40)", "rgb(216, 216, 216)"],
    images: [
      "/phone/1002.jpg",
      "/phone/1002a.jpg",
      "/phone/1002b.jpg",
      "/phone/1002c.jpg",
      "/phone/1002d.jpg",
    ],
  },
];

export default productData;
