import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <p>
          Hello! We're Back Market, the leading marketplace for refurbished
          devices. Our mission? To fight e-waste by giving expertly restored
          devices a second life.
        </p>
        <div className="left__right">
          <div className="botLeft">
            <div className="footer__column">
              <Link href="/" className="footer__column__header">
                About
              </Link>
              <div className="footer__column__content">
                <ul className="footer__links_list">
                  <li>
                    <Link href="/" onClick={toTop}>
                      About us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer__column">
              <Link href="/" className="footer__column__header">
                Contact us
              </Link>
              <div className="footer__column__content">
                <ul className="footer__links_list">
                  <li>
                    <Link href="/" onClick={toTop}>
                      work@hinwr.life
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer__column">
              <Link href="/" className="footer__column__header">
                Law and order
              </Link>
              <div className="footer__column__content">
                <ul className="footer__links_list">
                  <li>
                    <Link href="/privacy" onClick={toTop}>
                      Privacy Policy
                    </Link>
                  </li>
                  <li>Payments 100% secured</li>
                  <li>
                    <div style={{ display: "flex" }}>
                      <img alt="" src="/head/card1.svg" />
                      <img alt="" src="/head/card2.svg" />
                      <img alt="" src="/head/card3.svg" />
                      <img alt="" src="/head/card4.svg" />
                      <img alt="" src="/head/card5.svg" />
                      <img alt="" src="/head/card6.svg" />
                      <img alt="" src="/head/card7.svg" />
                      <img alt="" src="/head/card8.svg" />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="foot__foot">
          <div className="foot__img">
            <img alt="" src="/head/country1.svg" />
            <img alt="" src="/head/country2.svg" />
            <img alt="" src="/head/country3.svg" />
            <img alt="" src="/head/country4.svg" />
            <img alt="" src="/head/country5.svg" />
            <img alt="" src="/head/country6.svg" />
            <img alt="" src="/head/country7.svg" />
            <img alt="" src="/head/country8.svg" />
            <img alt="" src="/head/country9.svg" />
            <img alt="" src="/head/country10.svg" />
            <img alt="" src="/head/country11.svg" />
            <img alt="" src="/head/country12.svg" />
            <img alt="" src="/head/country13.svg" />
            <img alt="" src="/head/country14.svg" />
            <img alt="" src="/head/country15.svg" />
            <img alt="" src="/head/country16.svg" />
            <img alt="" src="/head/country17.svg" />
            <img alt="" src="/head/country18.svg" />
            <p> © 2024 Back Market</p>
            <img
              alt=""
              src="/head/googlePlay.svg"
              style={{
                width: "135px",
                height: "40px",
                marginLeft: "1.5%",
                marginRight: "0",
              }}
            />
            <img
              alt=""
              src="/head/appleStore.svg"
              style={{ width: "135px", height: "40px" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
