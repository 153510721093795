import React, { useContext, useState } from "react";
import { CartContext } from "../Component/CartContext";
import "../css/checkout.css";
import productData from "../Datas/productData";
import Header from "./Header";
import ReactFlagsSelect from "react-flags-select";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";

const CheckOut = () => {
  const { cartItems, clearCart } = useContext(CartContext);
  const [country, setCountry] = useState("US");
  const [code, setCode] = useState("");
  const [card, setCard] = useState("");
  const [firstName, setFirstName] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [date, setDate] = useState("");
  const [cvc, setCvc] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [selected, setSelected] = useState("US");

  const handlePlaceOrder = () => {
    const newErrors = {};

    if (!card) newErrors.card = "Card is required";
    if (!country) newErrors.country = "Country/Region is required";
    if (!code) newErrors.code = "Code number is required";
    if (!firstName) newErrors.firstName = "First name is required";
    if (!province) newErrors.province = "Province is required";
    if (!address) newErrors.address = "Address is required";
    if (!address2) newErrors.address2 = "Address2 is required";
    if (!city) newErrors.city = "Town / City is required";
    if (!date) newErrors.date = "Date is required";
    if (!cvc) newErrors.cvc = "CVC is required";

    // setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      alert("Please fill all fields");
    } else {
      alert("Order placed!");
      clearCart();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      navigate("/paysuccess");
    }
  };

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const product = getProductById(item.id);
      if (product) {
        const price = parseFloat(item.price);
        total += price * item.quantity;
      }
      return total;
    }, 0);
  };
  const formatCardNumber = (e) => {
    let { value } = e.target;
    value = value.replace(/\D/g, "");
    value = value.replace(/\d{4}(?=\d)/g, "$& ");
    value = value.substring(0, 19);
    setCard(value);
  };

  const formatDate = (e) => {
    let { value } = e.target;
    // 只允许输入数字和斜杠
    value = value.replace(/[^\d/]/g, "");
    if (value.length > 1 && value.indexOf("/") === -1) {
      value += "/";
    }
    value = value.substring(0, 5);
    setDate(value);
  };

  return (
    <>
      <Header />
      <div className="main-containerCheck">
        <div className="cDjmSt">
          <h3 className="erNcIJ">Pay by Card</h3>
          <div className="fTLgCV">
            <p className="gyujNp">
              Approx USD:${(calculateTotal() + 5.99).toFixed(2)}
            </p>
          </div>
        </div>
        <div className="check-custom-checkout-page">
          <div className="left">
            <div className="check-order-info">
              <h3>Billing details</h3>
              <form>
                <div className="form-group1 form-group-half">
                  <label>全名</label>
                  <input
                    type="text"
                    id="first-name"
                    name="first-name"
                    value={firstName}
                    placeholder="名字和姓氏"
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                  {errors.firstName && (
                    <p className="error">{errors.firstName}</p>
                  )}
                </div>

                <div className="form-group1 form-group-half">
                  <label>国家或地区</label>
                  <ReactFlagsSelect
                    selected={selected}
                    onSelect={(code) => {
                      setSelected(code);
                      setCountry(code);
                    }}
                    className="check-select"
                  />
                  {errors.country && <p className="error">{errors.country}</p>}
                </div>

                <div className="form-group1 form-group-half">
                  <label>地址第1行</label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  />
                  {errors.address && <p className="error">{errors.address}</p>}
                </div>
                <div className="form-group1 form-group-half">
                  <label>地址第2行</label>
                  <input
                    type="text"
                    id="address2"
                    name="address2"
                    value={address2}
                    onChange={(e) => setAddress2(e.target.value)}
                    required
                  />
                  {errors.address2 && (
                    <p className="error">{errors.address2}</p>
                  )}
                </div>
                <div className="form-group1 form-group-half">
                  <label>城市</label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                  {errors.city && <p className="error">{errors.city}</p>}
                </div>
                <div className="form-group1 form-group-half">
                  <label>省</label>
                  <input
                    type="text"
                    id="province"
                    name="province"
                    value={province}
                    onChange={(e) => setProvince(e.target.value)}
                    required
                  />
                  {errors.province && (
                    <p className="error">{errors.province}</p>
                  )}
                </div>

                <div className="form-group1 form-group-half">
                  <label>邮编</label>
                  <input
                    type="code"
                    id="code"
                    name="code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    required
                  />
                  {errors.code && <p className="error">{errors.code}</p>}
                </div>
              </form>
              <h3>Card Details</h3>
              <form>
                <div className="card-flex">
                  <div
                    className="form-group1 form-group-half"
                    style={{ display: "grid", marginRight: "4%" }}
                  >
                    <label>Card number</label>
                    <input
                      type="text"
                      id="card"
                      name="card"
                      value={card}
                      placeholder="1234 1234 1234 1234"
                      onChange={formatCardNumber}
                      required
                      style={{
                        width: "294.3px",
                        paddingLeft: "17%",
                      }}
                    />

                    {errors.card && <p className="error">{errors.card}</p>}
                  </div>
                  <div
                    className="form-group1 form-group-half"
                    style={{ display: "grid", marginRight: "4%" }}
                  >
                    <label>Exp. date</label>
                    <input
                      type="text"
                      id="date"
                      name="date"
                      value={date}
                      placeholder="MM/YY"
                      onChange={formatDate}
                      required
                      style={{ width: "97.03px" }}
                    />

                    {errors.date && <p className="error">{errors.date}</p>}
                  </div>
                  <div
                    className="form-group1 form-group-half"
                    style={{ display: "grid" }}
                  >
                    <label>Security code</label>
                    <input
                      type="text"
                      id="cvc"
                      name="cvc"
                      value={cvc}
                      placeholder="CVC"
                      onChange={(e) => setCvc(e.target.value)}
                      required
                      style={{ width: "97.03px" }}
                    />

                    {errors.cvc && <p className="error">{errors.cvc}</p>}
                  </div>
                </div>
                <div className="card-svg">
                  <img
                    width="35"
                    height="35"
                    src="https://img.icons8.com/ios-glyphs/50/515e80/credit-card-front.png"
                    alt="credit-card-front"
                  />
                </div>
              </form>
              <div>
                <button
                  className="check-checkout-button"
                  onClick={handlePlaceOrder}
                >
                  Pay Order
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckOut;
