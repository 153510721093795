import "../css/header.css";
import React from "react";
import { Link } from "react-router-dom";

function Header() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="header">
        <div className="header__inner">
          <div className="header__inner__left">
            <div className="header__nav__logo">
              <Link to="/">
                <img alt="" src="/head/Logo.svg" />
              </Link>
            </div>
          </div>
          <div className="header__nav__strapline">
            <nav className="navigation">
              <ul className=" main-nav">
                <li className="menu-item">
                  <Link to="/" className="homeToA" onClick={toTop}>
                    Home
                  </Link>
                </li>

                <li className="menu-item">
                  <Link to="/privacy" onClick={toTop}>
                    Privacy
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to="/" onClick={toTop}>
                    About Us
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="header__inner__right">
            <Link to="/cartpage">
              <img
                width="25"
                height="30"
                src="https://img.icons8.com/forma-thin/50/0e1016/shopping-bag.png"
                alt="shopping-bag"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="headerDiv">
        <div className="headerMid">
          <ul className="main-nav">
            <li>Good Deals</li>
            <li>Back to School</li>
            <li>Student Discount</li>
            <li>iPhone</li>

            <li>Smartphones</li>
            <li>Samsung Galaxy</li>
            <li>MacBook</li>
            <li>iPad</li>

            <li>AirPods</li>
            <li>Apple Watch</li>
            <li>Video Game Consoles</li>
            <li>iMac</li>

            <li>Windows Laptops</li>
            <li>Accessories</li>
            <li>Tablets</li>
            <li>Audio</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
