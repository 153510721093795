import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import productData from "../Datas/productData";
import { Link } from "react-router-dom";

function Main() {
  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="main-head">
          <p>$50 off select MacBooks with code MAC4SKOOL.</p>
        </div>
        <div>
          <Link to={`/shopDetails/1`} onClick={handleNavigate}>
            <img alt="" src="/head/main1.jpg" style={{ width: "100%" }} />
          </Link>
        </div>

        <div className="mainBox1">
          <div className="container-fluid">
            <div className="top-container">
              <h1>Most wanted</h1>
              <p>The tech in everyone’s search bar</p>
              <div className="product-grid">
                {productData
                  .filter((plastic) => plastic.id >= 2 && plastic.id <= 5)
                  .map((brand) => (
                    <div className="product-category" key={brand.id}>
                      <div className="image-container">
                        <Link
                          to={`/shopDetails/${brand.id}`}
                          onClick={handleNavigate}
                        >
                          <img alt="" src={brand.images[0]} />
                        </Link>
                        <Link
                          to={`/shopDetails/${brand.id}`}
                          onClick={handleNavigate}
                          style={{
                            WebkitLineClamp: "1",
                            display: "webkitBox",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {brand.main}
                        </Link>
                        <div
                          className="block__list__item__subtitle"
                          style={{
                            WebkitLineClamp: "1",
                            display: "webkitBox",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {brand.description}
                        </div>
                        <div className="price-information">
                          <div className="text-static-default-low caption">
                            Starting at
                          </div>
                          <div className="flex flex-col">
                            <div className="text-static-default-hi body-2-bold">
                              ${brand.price[0]}
                            </div>
                            <div className="text-static-default-low body-2 line-through">
                              ${brand.prePrice} <span>new</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="top-container">
              <h1>What’s actually “new” in the new iPad?</h1>
              <p>Here's the facts.</p>
              <div className="product-grid11">
                {productData
                  .filter((plastic) => plastic.id >= 6 && plastic.id <= 8)
                  .map((brand) => (
                    <div className="product-category11" key={brand.id}>
                      <div className="image-container11">
                        <h2>{brand.main}</h2>
                        <div className="block__list__item__subtitle">
                          {brand.description}
                        </div>
                        <Link
                          to={`/shopDetails/${brand.id}`}
                          onClick={handleNavigate}
                        >
                          <img alt="" src={brand.images[0]} />
                        </Link>

                        <div className="price-information">
                          <ul>
                            {brand.list.map((list, index) => (
                              <li key={index}>{list}</li>
                            ))}
                          </ul>
                        </div>
                        <div className="toDetailBtn">
                          <Link
                            to={`/shopDetails/${brand.id}`}
                            onClick={handleNavigate}
                          >
                            Buy now
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="top-container">
              <h1>Popular devices</h1>
              <p>You can totally sit with them</p>
              <div className="product-grid22">
                {productData
                  .filter((plastic) => plastic.id >= 9 && plastic.id <= 10)
                  .map((brand) => (
                    <div className="product-category22" key={brand.id}>
                      <div className="image-container22">
                        <Link
                          to={`/shopDetails/${brand.id}`}
                          onClick={handleNavigate}
                        >
                          <img alt="" src={brand.images[0]} />
                        </Link>
                        <h2>{brand.main}</h2>
                        <div className="block__list__item__subtitle">
                          {brand.description}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="product-grid33">
                {productData
                  .filter((plastic) => plastic.id >= 11 && plastic.id <= 13)
                  .map((brand) => (
                    <div className="product-category33" key={brand.id}>
                      <div className="image-container33">
                        <Link
                          to={`/shopDetails/${brand.id}`}
                          onClick={handleNavigate}
                        >
                          <img alt="" src={brand.images[0]} />
                        </Link>
                        <h2>{brand.main}</h2>
                        <div className="block__list__item__subtitle">
                          {brand.description}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="top-container">
              <h1>Behind the Tech</h1>
              <div className="product-grid">
                <iframe
                  width="100%"
                  height="415"
                  src="https://videos.ctfassets.net/mmeshd7gafk1/4bwx41LMSQ0o6mBF6iQREV/f9c33a06c5ea0319226798502795c3af/BATTERY-EN-ss.mp4"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="YouTube video"
                  style={{
                    borderRadius: "10px",
                    width: "256px",
                    height: "455px",
                  }}
                ></iframe>
                <video
                  aria-hidden="true"
                  class="m-auto block w-full rounded-lg h-full"
                  loop=""
                  poster="https://images.ctfassets.net/mmeshd7gafk1/Y5r9LOiLBqSVj73TwFHT6/b7973ed6e72b78c58d6fada942ba8a5b/EN_Mystery_Order_Thumbnail_Test_2.jpg"
                  data-test="7gI5DyoYVzAop49ar0T4JW"
                  style={{
                    borderRadius: "10px",
                  }}
                >
                  <source
                    src="//videos.ctfassets.net/mmeshd7gafk1/5NR5YKJ9X1beWkSFw2E4vv/2d33ab6c1a4af4a38748b3ac1abc70b2/V1_vertical_Mystery_orders_EN.mov"
                    type="video/quicktime"
                  />
                </video>
                <video
                  aria-hidden="true"
                  class="m-auto block w-full rounded-lg h-full"
                  loop=""
                  poster="https://images.ctfassets.net/mmeshd7gafk1/6dKgvdw1apQq9bO99r2ig2/414d4c20ad96de98ad9284b4c94722d8/V2-Thumbnail-vertical-EN.jpg"
                  data-test="3LQlKBPQERDI3juFGydCmX"
                  style={{
                    borderRadius: "10px",
                  }}
                >
                  <source
                    src="//videos.ctfassets.net/mmeshd7gafk1/1tKBCXe989lwAbp5LodXgD/99097bb13f54b04160cd44c8c31ffb65/IPAD-EN.mp4"
                    type="video/mp4"
                  />
                </video>
                <iframe
                  width="100%"
                  height="415"
                  src="https://videos.ctfassets.net/mmeshd7gafk1/21kshcQaaNz8bCm9ysrK67/5c7e47edd2fac8c932cfd76691d8d7df/NEW-BATTERY-EN-verticale.mp4"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="YouTube video"
                  style={{
                    borderRadius: "10px",
                    width: "256px",
                    height: "455px",
                  }}
                ></iframe>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="top-container">
              <h1>The latest in refurbished tech</h1>
              <div className="product-grid">
                <div className="tech__div">
                  <img alt="" src="/head/tech1.png" />
                  <div className="tech__txt">
                    <div className="tech_top">
                      Back Market Articles and Guides
                    </div>
                    <h3 className="tech_mid">
                      Refurbished vs new: Why refurb is better
                    </h3>
                    <p className="tech_bot">
                      When comparing new devices versus refurbished tech,
                      refurbished takes the cake. Here’s why.
                    </p>
                  </div>
                </div>
                <div className="tech__div">
                  <img alt="" src="/head/tech2.png" />
                  <div className="tech__txt">
                    <div className="tech_top">News</div>
                    <h3 className="tech_mid">
                      What’s the difference between a locked and unlocked phone?
                    </h3>
                    <p className="tech_bot">
                      What’s the difference between a locked and unlocked phone?
                      We break it down to help you find the right phone for you.
                    </p>
                  </div>
                </div>
                <div className="tech__div">
                  <img alt="" src="/head/tech3.jpg" />
                  <div className="tech__txt">
                    <div className="tech_top">News</div>
                    <h3 className="tech_mid">
                      How Trade-in helps the planet — and your wallet
                    </h3>
                    <p className="tech_bot">
                      Our trade in program is an easy, contract-free way to make
                      cash, help the planet, and clear out your old tech.
                    </p>
                  </div>
                </div>
                <div className="tech__div">
                  <img alt="" src="/head/tech4.jpg" />
                  <div className="tech__txt">
                    <div className="tech_top">News</div>
                    <h3 className="tech_mid">
                      What does refurbished mean? Used vs. refurbished explained
                    </h3>
                    <p className="tech_bot">
                      All refurbished devices are used, but not all used devices
                      are refurbished. What does refurbished mean? Read on to
                      learn more about used vs. refurbished tech.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="top-container">
              <h1>Accessories, PCs, and more</h1>
              <p>We’re more than just smartphones</p>
              <div className="product-grid22">
                {productData
                  .filter((plastic) => plastic.id >= 14 && plastic.id <= 15)
                  .map((brand) => (
                    <div className="product-category22" key={brand.id}>
                      <div className="image-container22">
                        <Link
                          to={`/shopDetails/${brand.id}`}
                          onClick={handleNavigate}
                        >
                          <img alt="" src={brand.images[0]} />
                        </Link>
                        <h2>{brand.main}</h2>
                        <div className="block__list__item__subtitle">
                          {brand.description}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="product-grid33">
                {productData
                  .filter((plastic) => plastic.id >= 16 && plastic.id <= 18)
                  .map((brand) => (
                    <div className="product-category33" key={brand.id}>
                      <div className="image-container33">
                        <Link
                          to={`/shopDetails/${brand.id}`}
                          onClick={handleNavigate}
                        >
                          <img alt="" src={brand.images[0]} />
                        </Link>
                        <h2>{brand.main}</h2>
                        <div className="block__list__item__subtitle">
                          {brand.description}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="trade__div">
            <div className="trade__img">
              <img alt="" src="/head/trade.png" />
            </div>
            <div className="trade__txt">
              <h2>Trading in your old tech is easier than a lot of things.</h2>
              <p>
                Earn cash when you trade in your forgotten tech. It’s a simple
                way to help do more with what we already have.
              </p>
            </div>
          </div>

          <div className="trade__div">
            <div className="trade__txt1">
              <h2>We’re B-Corp certified</h2>
              <p>
                Being a B-Corp means that we walk the walk when it comes to
                everything from our environmental impact to our hiring
                practices.
              </p>
            </div>

            <div className="trade__img">
              <img alt="" src="/head/trade1.webp" />
            </div>
          </div>

          <div className="container-fluid">
            <div className="top-container">
              <h1>In the news</h1>
              <div className="product-grid22" style={{ gap: "38px" }}>
                <div className="news__div">
                  <div className="body-1">
                    "‘It should be a right to fix your phone’: the boss of
                    booming secondhand tech firm Back Market"
                  </div>
                  <div>
                    <img alt="" src="/head/news1.png" />
                  </div>
                  <div class="body-2">06/2024</div>
                </div>
                <div className="news__div">
                  <div className="body-1">
                    "Back Market and bolt Announce Partnership in the United
                    States"
                  </div>
                  <div>
                    <img alt="" src="/head/news2.png" />
                  </div>
                  <div class="body-2">05/2024</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="else__div">
          <div className="else__div__inner">
            <h2>What else?</h2>
            <p>4 questions people always ask</p>
            <h2>How does Back Market guarantee quality?</h2>
            <p>
              From a pre-listing screening process for all sellers to “mystery
              orders” placed by Back Market’s Innovation Lab,
              <strong style={{ color: "#000", textDecoration: "underline" }}>
                we give 110% in making sure the Verified Refurbished tech we
                offer you is top-notch.
              </strong>
              It’s also why every device comes with a 1-year warranty and 30
              days to change your mind.
            </p>

            <h2>What’s the difference between refurbished and new?</h2>
            <p>
              On the outside, a refurbished smartphone looks and works like new.
              But it’s what's on the inside that really counts. Reborn tech
              <strong style={{ color: "#000", textDecoration: "underline" }}>
                helps keep e-waste out of our landfills, water, and air
              </strong>
              .
            </p>
            <h2>Can you help me recycle my old phone or other electronics?</h2>
            <p>
              We can do you one better — give you cash with
              <strong style={{ color: "#000", textDecoration: "underline" }}>
                Trade-In
              </strong>
              . You can get an offer in minutes from a refurbisher to trade in
              your old device. To make it extra juicy, we also cover the cost of
              shipping.
            </p>
            <h2>
              Was the T-800 in Terminator 2: Judgment Day (1991) technically
              “refurbished”?
            </h2>
            <p>
              Most likely. We’d need to hand Skynet over to our Innovation Lab
              before we say yes or no, but we have a hunch that it’s an “I’ll be
              back” scenario.
            </p>
          </div>
        </div>

        <div className="phone__catagory">
          <div className="phone__catagory__inner">
            <h2 style={{ marginBottom: "3.5rem" }}>
              Discover all of our high-tech devices Lovingly restored and
              squeaky clean!
            </h2>
            <h2>
              Smartphones - Tablets - Laptops - Gaming consoles - Smartwatches -
              Audio - More
            </h2>
            <div className="ul__grid">
              <ul style={{ marginTop: "3%" }}>
                <li>
                  <h2>Apple iPhone</h2>
                  <ul className="mid__li">
                    <li>
                      <p>See all</p>
                    </li>
                    <li>
                      <p>iPhone 14</p>
                    </li>
                    <li>
                      <p>iPhone 13</p>
                    </li>
                    <li>
                      <p>iPhone 12</p>
                    </li>
                    <li>
                      <p>iPhone 11</p>
                    </li>
                    <li>
                      <p>iPhone SE(2022)</p>
                    </li>
                    <li>
                      <p>iPhone X</p>
                    </li>
                    <li>
                      <p>iPhone XR</p>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul style={{ marginTop: "3%" }}>
                <li>
                  <h2>Samsung Galaxy</h2>
                  <ul className="mid__li">
                    <li>
                      <p>See all</p>
                    </li>
                    <li>
                      <p>Galaxy S23 series</p>
                    </li>
                    <li>
                      <p>Galaxy S22 series</p>
                    </li>
                    <li>
                      <p>Galaxy S21 series</p>
                    </li>
                    <li>
                      <p>Galaxy Note series</p>
                    </li>
                    <li>
                      <p>Galaxy Z Fold series</p>
                    </li>
                    <li>
                      <p>Galaxy Z Flip series</p>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul style={{ marginTop: "3%" }}>
                <li>
                  <h2>Google Pixel</h2>
                  <ul className="mid__li">
                    <li>
                      <p>See all</p>
                    </li>
                    <li>
                      <p>Google Pixel 7 series</p>
                    </li>
                    <li>
                      <p>Google Pixel 6 series</p>
                    </li>
                    <li>
                      <p>Google Pixel 5 series</p>
                    </li>
                    <li>
                      <p>Google Pixel 4 series</p>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul style={{ marginTop: "3%" }}>
                <li>
                  <h2>More brands</h2>
                  <ul className="mid__li">
                    <li>
                      <p>See all</p>
                    </li>
                    <li>
                      <p>Huawei</p>
                    </li>
                    <li>
                      <p>OnePlus</p>
                    </li>
                    <li>
                      <p>Motorola</p>
                    </li>
                    <li>
                      <p>Nokia</p>
                    </li>
                    <li>
                      <p>Sony</p>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul style={{ marginTop: "3%" }}>
                <li>
                  <h2>Smartphone accessories</h2>
                  <ul className="mid__li">
                    <li>
                      <p>See all</p>
                    </li>
                    <li>
                      <p>Cases</p>
                    </li>
                    <li>
                      <p>Chargers</p>
                    </li>
                    <li>
                      <p>Screen protectors</p>
                    </li>
                    <li>
                      <p>AirPods</p>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul style={{ marginTop: "3%" }}>
                <li>
                  <h2>Back Market services</h2>
                  <ul className="mid__li">
                    <li>
                      <p>Trade in your smartphone</p>
                    </li>
                    <li>
                      <p>BackUp protection plans</p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul style={{ marginTop: "3%" }}>
                <li>
                  <h2>Buying guides</h2>
                  <ul className="mid__li">
                    <li>
                      <p>See all guides</p>
                    </li>
                    <li>
                      <p>iPhone 14 vs iPhone 15: is it</p>
                    </li>
                    <li>
                      <p>worth the upgrade?</p>
                    </li>
                    <li>
                      <p>Google Pixel 6 Review</p>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul style={{ marginTop: "3%" }}>
                <li>
                  <h2>Apple iPad</h2>
                  <ul className="mid__li">
                    <li>
                      <p>See all</p>
                    </li>
                    <li>
                      <p>iPad Air series</p>
                    </li>
                    <li>
                      <p>iPad Pro series</p>
                    </li>
                    <li>
                      <p>iPad mini series</p>
                    </li>
                    <li>
                      <p>iPad series</p>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul style={{ marginTop: "3%" }}>
                <li>
                  <h2>Samsung Galaxy Tab</h2>
                  <ul className="mid__li">
                    <li>
                      <p>See all</p>
                    </li>
                    <li>
                      <p>Galaxy Tab S series</p>
                    </li>
                    <li>
                      <p>Galaxy Tab A series</p>
                    </li>
                    <li>
                      <p>Galaxy Tab E series</p>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul style={{ marginTop: "3%" }}>
                <li>
                  <h2>Microsoft Surface</h2>
                  <ul className="mid__li">
                    <li>
                      <p>See all</p>
                    </li>
                    <li>
                      <p>Microsoft Surface Pro X</p>
                    </li>
                    <li>
                      <p>Microsoft Surface Pro 9</p>
                    </li>
                    <li>
                      <p>Microsoft Surface Pro 8</p>
                    </li>
                    <li>
                      <p>Microsoft Surface Pro 7</p>
                    </li>
                    <li>
                      <p>Microsoft Surface Pro 6</p>
                    </li>
                    <li>
                      <p>Microsoft Surface Go</p>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul style={{ marginTop: "3%" }}>
                <li>
                  <h2>More brands</h2>
                  <ul className="mid__li">
                    <li>
                      <p>See all</p>
                    </li>
                    <li>
                      <p>Asus</p>
                    </li>
                    <li>
                      <p>Lenovo</p>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul style={{ marginTop: "3%" }}>
                <li>
                  <h2>Other</h2>
                  <ul className="mid__li">
                    <li>
                      <p>E-readers</p>
                    </li>
                    <li>
                      <p>Tablets for kids</p>
                    </li>
                    <li>
                      <p>Apple Pencil</p>
                    </li>
                    <li>
                      <p>Tablet accessories</p>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul style={{ marginTop: "3%" }}>
                <li>
                  <h2>Back Market services</h2>
                  <ul className="mid__li">
                    <li>
                      <p>Trade in your tablet</p>
                    </li>
                    <li>
                      <p>BackUp protection plans</p>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul style={{ marginTop: "3%" }}>
                <li>
                  <h2>Buying guides</h2>
                  <ul className="mid__li">
                    <li>
                      <p>See all guides</p>
                    </li>
                    <li>
                      <p>Top iPad picks</p>
                    </li>
                    <li>
                      <p>iPad Air (2022) vs iPad Mini</p>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul style={{ marginTop: "3%" }}>
                <li>
                  <h2>Apple MacBook</h2>
                  <ul className="mid__li">
                    <li>
                      <p>See all</p>
                    </li>
                    <li>
                      <p>MacBook Pro</p>
                    </li>
                    <li>
                      <p>MacBook Air</p>
                    </li>
                    <li>
                      <p>MacBook</p>
                    </li>
                    <li>
                      <p>MacBook M1 series</p>
                    </li>
                    <li>
                      <p>MacBook M2 series</p>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
