import React, { useContext } from "react";
import { CartContext } from "../Component/CartContext";
import productData from "../Datas/productData";
import Header from "./Header";
import "../css/cartpage.css";
import { useNavigate, Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const StyledTableContainer = styled(TableContainer)({
  minWidth: 650,
});

const StyledImage = styled("img")({
  width: 112,
  height: 124,
  objectFit: "cover",
});

const StyledTableCell = styled(TableCell)({
  display: "flex",
  alignItems: "center",
  verticalAlign: "middle",
  fontSize: "16px",
  borderRight: "1px dotted #ccc",
});

const CartPage = () => {
  const { cartItems, removeFromCart, updateQuantity } = useContext(CartContext);
  const navigate = useNavigate();

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const toCheckOut = () => {
    if (cartItems.length === 0) {
      alert("Please add items to your cart before proceeding to checkout.");
      return;
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/checkout");
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const product = getProductById(item.id);
      if (product) {
        const price = parseFloat(item.price);
        total += price * item.quantity;
      }
      return total;
    }, 0);
  };

  const calculateItemTotal = (itemId, itemAppearance, itemColor, quantity) => {
    const product = getProductById(itemId);
    if (!product) return 0;
    const item = cartItems.find(
      (cartItem) =>
        cartItem.id === itemId &&
        cartItem.appearance === itemAppearance &&
        cartItem.color === itemColor
    );
    if (!item) return 0;
    const price = parseFloat(item.price);
    return price * quantity || price;
  };

  const handleInputChange = (itemId, appearance, color, e) => {
    const newQuantity = parseInt(e.target.value, 10);
    if (isNaN(newQuantity) || newQuantity < 0) return;

    const item = cartItems.find(
      (cartItem) =>
        cartItem.id === itemId &&
        cartItem.appearance === appearance &&
        cartItem.color === color
    );

    if (item) {
      updateQuantity(
        itemId,
        newQuantity,
        item.price,
        item.appearance,
        item.color
      );
    }
  };

  return (
    <>
      <div className="main-container">
        <Header />
        <div className="cart-page">
          <div className="basket-title">
            <h1>Shopping Basket</h1>
          </div>
          <div className="cart-total">
            <div className="cart-items">
              {cartItems.length === 0 ? (
                <h3 style={{ color: "black", textAlign: "center" }}>
                  Your Cart is Empty!
                </h3>
              ) : (
                <StyledTableContainer component={Paper}>
                  <Table aria-label="shopping cart table">
                    <TableBody>
                      {cartItems.map((item, index) => {
                        const product = getProductById(item.id);
                        return (
                          <TableRow key={index}>
                            <StyledTableCell component="th" scope="row">
                              <StyledImage
                                src={product.images[0]}
                                alt="Product"
                                style={{
                                  width: "218px",
                                  objectFit: "contain",
                                }}
                              />
                            </StyledTableCell>
                            <TableCell
                              align="left"
                              style={{
                                verticalAlign: "middle",
                                borderRight: "1px dotted #ccc",
                              }}
                            >
                              <h4>{product.name}</h4>
                              <p>Condition:{item.appearance}</p>
                              <p>Color:{item.color}</p>
                              <h4>
                                $
                                {calculateItemTotal(
                                  item.id,
                                  item.appearance,
                                  item.color,
                                  item.quantity
                                ).toFixed(2)}
                              </h4>
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              <select
                                value={item.quantity || 1}
                                className="cartInput"
                                onChange={(e) =>
                                  handleInputChange(
                                    item.id,
                                    item.appearance,
                                    item.color,
                                    e
                                  )
                                }
                              >
                                {[...Array(10).keys()].map((num) => (
                                  <option key={num + 1} value={num + 1}>
                                    {num + 1}
                                  </option>
                                ))}
                              </select>
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                verticalAlign: "middle",
                                borderRight: "2px solid rgb(243, 243, 243)",
                              }}
                            >
                              <Link
                                to="/cartpage"
                                onClick={() =>
                                  removeFromCart(
                                    item.id,
                                    item.appearance,
                                    item.color
                                  )
                                }
                                style={{
                                  cursor: "context-menu",
                                  textDecoration: "underline",
                                  fontFamily: "Libre Baskerville",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                <button className="remove__btn">Remove</button>
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              )}
              {cartItems.length > 0 && (
                <div className="cart-summary">
                  <div className="rightSum">
                    <table>
                      <tbody>
                        <tr>
                          <td className="td-left">Sub-total:</td>
                          <td> £{calculateTotal().toFixed(2)}</td>
                        </tr>
                        <tr className="express">
                          <td className="td-left">Quality Assurance Fee</td>
                          <td>£5.99</td>
                        </tr>
                        <tr className="td-left">
                          <td className="td-left">Estimated taxes</td>
                          <td>Pending</td>
                        </tr>
                        <tr className="td-left">
                          <td className="td-left">Total:</td>
                          <td>
                            <strong>
                              {(calculateTotal() + 5.99).toFixed(2)}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="btn___box">
                      <button className="checkout-button" onClick={toCheckOut}>
                        Checkout
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartPage;
