import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const [quantities] = useState("1");
  const { addToCart } = useContext(CartContext);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedAppearance, setSelectedAppearance] = useState("Fair");
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleAddToCart = (id) => {
    if (!selectedColor) {
      alert("Please select a color!");
      return;
    }
    if (!selectedAppearance) {
      alert("Please select a appearance!");
      return;
    }

    addToCart(
      id,
      product.price[product.appearance.indexOf(selectedAppearance)],
      quantities,
      selectedAppearance,
      selectedColor
    );
    navigate("/cartpage");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (!product) {
    return <div>Product not found</div>;
  }

  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="main-head">
          <p>$50 off select MacBooks with code MAC4SKOOL.</p>
        </div>
        <div className="iphone_nav">
          <nav>
            <ol>
              <li>
                <Link to="/">Home </Link>
              </li>
              <li>
                <img
                  width="24"
                  height="24"
                  src="https://img.icons8.com/material-sharp/50/forward.png"
                  alt="forward"
                />
              </li>
              <li>{product.name}</li>
            </ol>
          </nav>
        </div>
        <div
          className="mainBox1"
          style={{ backgroundColor: "#fff", padding: "0" }}
        >
          <div className="container-fluid">
            <div className="iphone_details">
              {/* <img alt="" src={product.images[0]} /> */}
              <div className="product-images">
                <div className="carousel">
                  {product.images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`${product.name}`}
                      style={{
                        display: index === currentIndex ? "block" : "none",
                      }}
                    />
                  ))}
                </div>
                <div className="carousel-buttons">
                  {product.images.map((image, index) => (
                    <button key={index} onClick={() => setCurrentIndex(index)}>
                      <img src={image} alt={`${product.name}`} />
                    </button>
                  ))}
                </div>
              </div>
              <div className="iphone_right_details">
                <div className="right_details_inner">
                  <h1>{product.name}</h1>
                  <p className="underline-offset-2">
                    {product.reviews}&nbsp;reviews
                  </p>
                  <div className="price_add">
                    <div className="price_preprice">
                      <div className="flex items-baseline gap-4">
                        <h2
                          className="heading-2"
                          data-qa="productpage-product-price"
                        >
                          $
                          {
                            product.price[
                              product.appearance.indexOf(selectedAppearance)
                            ]
                          }
                        </h2>
                        <span className="body-2-bold whitespace-nowrap">
                          before trade-in
                        </span>
                      </div>
                      <div className="text-static-default-low body-2-striked cursor-pointer whitespace-nowrap">
                        ${product.prePrice} new
                      </div>
                    </div>
                    <div className="add_love">
                      <button onClick={() => handleAddToCart(product.id)}>
                        Add to cart
                      </button>
                    </div>
                  </div>

                  <div className="text-static-default-hi body-2-bold mb-32">
                    <div className="ship_inner_div">
                      <div className="bg-static-info-mid rounded-sm mr-12 flex h-36 w-36 shrink-0 items-center justify-center">
                        <img
                          width="24"
                          height="24"
                          src="https://img.icons8.com/material-outlined/24/suv-2.png"
                          alt="suv-2"
                        />
                      </div>
                      <div>
                        <div className="body-2">
                          Free delivery by Sep 11 - Sep 12
                        </div>
                        <div className="caption">
                          Express delivery by Sep 10 - Sep 11 from $30.00
                        </div>
                      </div>
                    </div>
                    <div className="ship_inner_div">
                      <div className="bg-static-info-mid rounded-sm mr-12 flex h-36 w-36 shrink-0 items-center justify-center">
                        <img
                          width="24"
                          height="24"
                          src="https://img.icons8.com/badges/50/unlock.png"
                          alt="unlock"
                        />
                      </div>
                      <div>
                        <div className="body-2">Works with all carriers</div>
                      </div>
                    </div>
                    <div className="ship_inner_div">
                      <div className="bg-static-info-mid rounded-sm mr-12 flex h-36 w-36 shrink-0 items-center justify-center">
                        <img
                          width="24"
                          height="24"
                          src="https://img.icons8.com/material-outlined/24/security-checked.png"
                          alt="security-checked"
                        />
                      </div>
                      <div>
                        <div className="body-2">Free 30-day returns</div>
                        <div className="caption">1-year warranty</div>
                      </div>
                    </div>
                    <div className="ship_inner_div">
                      <div className="bg-static-info-mid rounded-sm mr-12 flex h-36 w-36 shrink-0 items-center justify-center">
                        <img
                          width="24"
                          height="24"
                          src="https://img.icons8.com/material-outlined/24/verified-account.png"
                          alt="verified-account"
                        />
                      </div>
                      <div>
                        <div className="body-2">
                          Verified Refurbished in the US
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="item__condition">
                    <p className="">Condition</p>
                    <div className="condition__select">
                      {product.appearance.map((appearance, index) => (
                        <button
                          key={index}
                          class="mr-8 min-w-[100px] grow text-action-default-hi body-1"
                          style={{
                            border:
                              appearance === selectedAppearance
                                ? "2px solid #000"
                                : "1px solid #ccc",
                            boxShadow:
                              appearance === selectedAppearance
                                ? "0px 0px 6px 2px rgba(0, 0, 0, 0.3)"
                                : "none",
                            backgroundColor:
                              appearance === selectedAppearance
                                ? "#f6f2fd"
                                : "",
                          }}
                          onClick={() => setSelectedAppearance(appearance)}
                        >
                          <div className="appear">{appearance}</div>
                          <div className="appear__price">
                            ${product.price[index]}
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className="item__condition">
                    <p className="">Color</p>
                    <div className="condition__select">
                      {product.color.map((color, index) => (
                        <button
                          key={index}
                          className="mr-8 min-w-[100px] grow text-action-default-hi body-1"
                          style={{
                            border:
                              color === selectedColor
                                ? "2px solid #000"
                                : "1px solid #ccc",
                            backgroundColor:
                              color === selectedColor ? "#f6f2fd" : "",
                          }}
                          onClick={() => setSelectedColor(color)}
                        >
                          <div
                            style={{
                              backgroundColor: product.bacColor[index],
                              width: "17px",
                              height: "17px",
                              borderRadius: "624.9375rem",
                              display: "inline-block",
                              marginRight: "10px",
                              marginBottom: "-3px",
                              border:
                                color === selectedColor
                                  ? "2px solid #000"
                                  : "none",
                            }}
                          ></div>
                          {color}
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className="item__condition">
                    <p className="">Comes with</p>
                    <div>
                      <div className="bg-action-default-mid text-action-default-hi rounded-full body-2 flex h-36 w-fit shrink-0 items-center px-1212">
                        <img
                          width="24"
                          height="24"
                          src="https://img.icons8.com/ios-filled/50/tesla-supercharger-pin.png"
                          alt="tesla-supercharger-pin"
                        />
                        Power adapter
                      </div>
                    </div>
                  </div>

                  <div className="mb-32">
                    <div className="border-static-default-low rounded-md box-border flex flex-col items-stretch justify-between border p-24">
                      <div className="mb-12 flex items-center justify-between">
                        Get this for even less
                      </div>
                      <div>
                        Like <strong>starting from $354.98</strong>. We’ll buy
                        back your old tech for cash.
                      </div>
                    </div>
                  </div>

                  <div className="accepts"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mainBox1">
          <div className="container-fluid">
            <div className="top-container">
              <h1>You may also like</h1>
              <div className="product-grid">
                {productData
                  .filter((plastic) => plastic.id >= 2 && plastic.id <= 5)
                  .map((brand) => (
                    <div className="product-category" key={brand.id}>
                      <div className="image-container">
                        <Link
                          to={`/shopDetails/${brand.id}`}
                          onClick={handleNavigate}
                        >
                          <img alt="" src={brand.images[0]} />
                        </Link>
                        <Link
                          to={`/shopDetails/${brand.id}`}
                          onClick={handleNavigate}
                          style={{
                            height: "48px",
                          }}
                        >
                          {brand.main}
                        </Link>
                        <div
                          className="block__list__item__subtitle"
                          style={{
                            WebkitLineClamp: "1",
                            display: "webkitBox",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {brand.description}
                        </div>
                        <div className="price-information">
                          <div className="text-static-default-low caption">
                            Starting at
                          </div>
                          <div className="flex flex-col">
                            <div className="text-static-default-hi body-2-bold">
                              ${brand.price[0]}
                            </div>
                            <div className="text-static-default-low body-2 line-through">
                              ${brand.prePrice} <span>new</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetail;
