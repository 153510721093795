import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/privacy.css";
import { Link } from "react-router-dom";

function Acceptable() {
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="main-head">
          <p>Our customers say</p>
          <h6>Excellent</h6>
          <p className="p-img">
            <img
              width="50"
              height="50"
              src="https://img.icons8.com/android/50/00b67a/rating.png"
              alt="rating"
            />
            <img
              width="50"
              height="50"
              src="https://img.icons8.com/android/50/00b67a/rating.png"
              alt="rating"
            />
            <img
              width="50"
              height="50"
              src="https://img.icons8.com/android/50/00b67a/rating.png"
              alt="rating"
            />
            <img
              width="50"
              height="50"
              src="https://img.icons8.com/android/50/00b67a/rating.png"
              alt="rating"
            />
            <img
              width="50"
              height="50"
              src="https://img.icons8.com/android/50/00b67a/rating.png"
              alt="rating"
            />
          </p>
          <p>4.8 out of 5 based on 1,029 reviews</p>
          <img
            src="https://img.icons8.com/ios-glyphs/50/00b67a/star.png"
            alt="star"
            style={{ width: "20px", height: "20px" }}
          />
          <p>Trustpilot</p>
        </div>

        <div className="mainBox1">
          <div className="container-fluid">
            <div className="signpost1">
              <p>
                <small>
                  J&amp;A (International) Limited, Insignia House, Vale Road
                  Industrial Estate, Spilsby, Lincolnshire, PE23 5HE. Telephone:
                  +44 (0)1790 752757
                </small>
              </p>

              <h1>
                J&amp;A (International) Limited Group - Acceptable Use Policy
              </h1>
              <p>
                This acceptable use policy sets out the terms between you and us
                under which you may access our websites:
              </p>

              <table class="website-list">
                <tbody>
                  <tr>
                    <td>
                      www.3d-aufkleber-dsi.de
                      <br />
                      www.adesivi-resinati-dsi.it
                      <br />
                      www.colour-frog.co.uk
                      <br />
                      www.creativetransfers.co.uk
                      <br />
                      www.dome-autocollant.fr
                      <br />
                      www.dome-stickers.be
                      <br />
                      www.fast-pens.co.uk
                      <br />
                      www.gotaderesina.es
                      <br />
                      www.idworkwear.co.uk
                      <br />
                      www.ja-int.at
                      <br />
                      www.ja-int.be
                      <br />
                      www.ja-int.ch
                      <br />
                      www.ja-int.co.uk
                      <br />
                      www.ja-int.com
                      <br />
                      www.ja-int.com.au
                      <br />
                      www.ja-int.de
                      <br />
                      www.ja-int.dk
                      <br />
                      www.ja-int.es
                      <br />
                      www.ja-int.fr
                      <br />
                      www.ja-int.ie
                      <br />
                      www.ja-int.it
                      <br />
                      www.ja-int.nl
                      <br />
                      www.ja-int.se
                      <br />
                    </td>
                    <td>
                      www.labels-international.co.uk
                      <br />
                      www.namebadgesinternational.ae
                      <br />
                      www.namebadgesinternational.at
                      <br />
                      www.namebadgesinternational.be
                      <br />
                      www.namebadgesinternational.ch
                      <br />
                      www.namebadgesinternational.co.uk
                      <br />
                      www.namebadgesinternational.com
                      <br />
                      www.namebadgesinternational.dk
                      <br />
                      www.namebadgesinternational.es
                      <br />
                      www.namebadgesinternational.fr
                      <br />
                      www.namebadgesinternational.ie
                      <br />
                      www.namebadgesinternational.it
                      <br />
                      www.namebadgesinternational.nl
                      <br />
                      www.namebadgesinternational.se
                      <br />
                      www.namensschilder-nbi.de
                      <br />
                      www.stickersinternational.co.uk
                      <br />
                      www.stickersinternational.com
                      <br />
                      www.stickersinternational.ie
                      <br />
                      www.stickersinternational.nl
                      <br />
                      www.stickersinternational.be
                      <br />
                      www.stickersinternational.it
                      <br />
                      www.stickersinternational.es
                      <br />
                      www.profile-packaging.co.uk
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                (a reference to<strong> “our site” </strong>or
                <strong> “our sites” </strong>is a reference to any of the
                above)
              </p>
              <p>
                This acceptable use policy applies to all users of, and visitors
                to, our site.
              </p>
              <p>
                Your use of our site means that you accept, and agree to abide
                by, all the policies in this acceptable use policy, which
                supplement our
                <Link to="/terms">Terms of Website Use</Link>.
              </p>
              <p>
                <strong>Information about us and contacting us</strong>
              </p>
              <p>
                Our sites are websites operated by J&amp;A (International)
                Limited ("<strong>We</strong>"). We are registered in England
                and Wales under company number 01567572, we are a limited
                company. We have our registered office and trading address at
                Insignia House, Lincolnshire, PE23 5HE. Our VAT number is
                GB351990440.
              </p>
              <p>
                You can contact us by telephone on: +44 (0)1790 752757, Fax us
                on +44 (0)1790 754132 or email us on
                <Link to="mailto:work@hinwr.life">work@hinwr.life</Link>
              </p>
              <p>
                <strong>Prohibited uses</strong>
              </p>
              <p>
                You may use our site only for lawful purposes. You may not use
                our site:
              </p>
              <ul>
                <li>
                  In any way that breaches any applicable local, national or
                  international law or regulation.
                </li>
                <li>
                  In any way that is unlawful or fraudulent, or has any unlawful
                  or fraudulent purpose or effect.
                </li>
                <li>
                  For the purpose of harming or attempting to harm minors in any
                  way.
                </li>
                <li>
                  To send, knowingly receive, upload, download, use or re-use
                  any material which does not comply with our content standards
                  below.
                </li>
                <li>
                  To transmit, or procure the sending of, any unsolicited or
                  unauthorised advertising or promotional material or any other
                  form of similar solicitation (spam).
                </li>
                <li>
                  To knowingly transmit any data, send or upload any material
                  that contains viruses, Trojan horses, worms, time-bombs,
                  keystroke loggers, spyware, adware or any other harmful
                  programs or similar computer code designed to adversely affect
                  the operation of any computer software or hardware.
                </li>
              </ul>
              <p>You also agree:</p>
              <ul>
                <li>
                  Not to reproduce, duplicate, copy or re-sell any part of our
                  site in contravention of the provisions of our
                  <Link to="/terms">Terms of Website Use</Link>.
                </li>
                <li>
                  Not to access without authority, interfere with, damage or
                  disrupt:
                  <ul>
                    <li>any part of our site;</li>
                    <li>
                      any equipment or network on which our site is stored;
                    </li>
                    <li>any software used in the provision of our site; or</li>
                    <li>
                      any equipment or network or software owned or used by any
                      third party.
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                <strong>Interactive services</strong>
              </p>
              <p>
                We may from time to time provide interactive services on our
                site, including, without limitation, Chat rooms, Bulletin
                boards, social media or other interactive features (
                <strong>interactive services</strong>).
              </p>
              <p>
                Where we do provide any interactive service, we will provide
                clear information to you about the kind of service offered, if
                it is moderated and what form of moderation is used (including
                whether it is human or technical).
              </p>
              <p>
                We will do our best to assess any possible risks for users (and
                in particular, for children) from third parties when they use
                any interactive service provided on our site, and we will decide
                in each case whether it is appropriate to use moderation of the
                relevant service (including what kind of moderation to use) in
                the light of those risks. However, we are under no obligation to
                oversee, monitor or moderate any interactive service we provide
                on our site, and we expressly exclude our liability for any loss
                or damage arising from the use of any interactive service by a
                user in contravention of our content standards, whether the
                service is moderated or not.
              </p>
              <p>
                The use of any of our interactive services by a minor is subject
                to the consent of their parent or guardian. We advise parents
                who permit their children to use an interactive service that it
                is important that they communicate with their children about
                their safety online, as moderation is not fool proof. Minors who
                are using any interactive service should be made aware of the
                potential risks to them.
              </p>
              <p>
                Where we do moderate an interactive service, we will normally
                provide you with a means of contacting the moderator, should a
                concern or difficulty arise.
              </p>
              <p>
                <strong>Content standards</strong>
              </p>
              <p>
                These content standards apply to any and all material which you
                contribute to our site (<strong>contributions</strong>), and to
                any interactive services associated with it.
              </p>
              <p>
                You must comply with the spirit and the letter of the following
                standards. The standards apply to each part of any contribution
                as well as to its whole.
              </p>
              <p>Contributions must:</p>
              <ul>
                <li>Be accurate (where they state facts).</li>
                <li>Be genuinely held (where they state opinions).</li>
                <li>
                  Comply with applicable law in the UK and in any country from
                  which they are posted.
                </li>
              </ul>
              <p>Contributions must not:</p>
              <ul>
                <li>Contain any material which is defamatory of any person.</li>
                <li>
                  Contain any material which is obscene, offensive, hateful or
                  inflammatory.
                </li>
                <li>Promote sexually explicit material.</li>
                <li>Promote violence.</li>
                <li>
                  Promote discrimination based on race, sex, religion,
                  nationality, disability, sexual orientation or age.
                </li>
                <li>
                  Infringe any copyright, database right or trade mark of any
                  other person.
                </li>
                <li>Be likely to deceive any person.</li>
                <li>
                  Be made in breach of any legal duty owed to a third party,
                  such as a contractual duty or a duty of confidence.
                </li>
                <li>Promote any illegal activity.</li>
                <li>
                  Be threatening, abuse or invade another’s privacy, or cause
                  annoyance, inconvenience or needless anxiety.
                </li>
                <li>
                  Be likely to harass, upset, embarrass, alarm or annoy any
                  other person.
                </li>
                <li>
                  Be used to impersonate any person, or to misrepresent your
                  identity or affiliation with any person.
                </li>
                <li>
                  Give the impression that they emanate from us, if this is not
                  the case.
                </li>
                <li>
                  Advocate, promote or assist any unlawful act such as (by way
                  of example only) copyright infringement or computer misuse.
                </li>
              </ul>
              <p>
                <strong>Suspension and termination</strong>
              </p>
              <p>
                We will determine, in our discretion, whether there has been a
                breach of this acceptable use policy through your use of our
                site. When a breach of this policy has occurred, we may take
                such action as we deem appropriate.&nbsp;
              </p>
              <p>
                Failure to comply with this acceptable use policy constitutes a
                material breach of the
                <Link to="/terms">Terms of Website Use</Link>
                upon which you are permitted to use our site, and may result in
                our taking all or any of the following actions:
              </p>
              <ul>
                <li>
                  Immediate, temporary or permanent withdrawal of your right to
                  use our site.
                </li>
                <li>
                  Immediate, temporary or permanent removal of any posting or
                  material uploaded by you to our site.
                </li>
                <li>Issue of a warning to you.</li>
                <li>
                  Legal proceedings against you for reimbursement of all costs
                  on an indemnity basis (including, but not limited to,
                  reasonable administrative and legal costs) resulting from the
                  breach.
                </li>
                <li>Further legal action against you.</li>
                <li>
                  Disclosure of such information to law enforcement authorities
                  as we reasonably feel is necessary.
                </li>
              </ul>
              <p>
                We exclude liability for actions taken in response to breaches
                of this acceptable use policy. The responses described in this
                policy are not limited, and we may take any other action we
                reasonably deem appropriate.
              </p>
              <p>
                <strong>Changes to the acceptable use policy</strong>
              </p>
              <p>
                We may revise this acceptable use policy at any time by amending
                this page. You are expected to check this page from time to time
                to take notice of any changes we make, as they are legally
                binding on you. Some of the provisions contained in this
                acceptable use policy may also be superseded by provisions or
                notices published elsewhere on our site.
              </p>
            </div>

            <div className="request-cta">
              <div className="request-cta-inner">
                <img alt="" src="/head/cheese.png" />
                <div className="cta-txt">
                  Want to see physical samples of our products?
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Acceptable;
