import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/privacy.css";
import { Link } from "react-router-dom";

function Privacy() {
  const handleNavigateToTermPdf = (e) => {
    e.preventDefault();
    window.open("/head/Privacy_Policy_US_02-04-2022.pdf", "_blank");
  };
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="mainBox1">
          <div className="container-fluid" style={{ paddingBottom: "3%" }}>
            <div className="iphone_nav">
              <nav>
                <ol>
                  <li>
                    <Link to="/">Home </Link>
                  </li>
                  <li>
                    <img
                      width="24"
                      height="24"
                      src="https://img.icons8.com/material-sharp/50/forward.png"
                      alt="forward"
                    />
                  </li>
                  <li>Privacy Policy</li>
                </ol>
              </nav>
            </div>
            <h1>Privacy Policy</h1>
            <div className="pdf_div">
              <button onClick={handleNavigateToTermPdf}>View PDF</button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Privacy;
