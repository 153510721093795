import React, { createContext, useState, useEffect } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem("cartItems");
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (productId, price, quantity, appearance, color) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find(
        (item) =>
          item.id === productId &&
          item.appearance === appearance &&
          item.color === color
      );

      if (existingItem) {
        return prevItems.map((item) =>
          item.id === productId &&
          item.appearance === appearance &&
          item.color === color
            ? { ...item, quantity: item.quantity + quantity }
            : item
        );
      } else {
        const newItem = {
          id: productId,
          price,
          quantity,
          appearance,
          color,
        };
        return [...prevItems, newItem];
      }
    });
  };

  const removeFromCart = (productId, appearance, color) => {
    setCartItems((prevItems) =>
      prevItems.filter(
        (item) =>
          !(
            item.id === productId &&
            item.appearance === appearance &&
            item.color === color
          )
      )
    );
  };

  const updateQuantity = (id, quantity, price, appearance, color) => {
    setCartItems((prevItems) => {
      return prevItems.map((item) => {
        if (
          item.id === id &&
          item.appearance === appearance &&
          item.color === color
        ) {
          return { ...item, quantity, price };
        }
        return item;
      });
    });
  };

  const clearCart = () => {
    setCartItems([]);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        updateQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
