import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/about.css";

function About() {
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="main-head">
          <p>Our customers say</p>
          <h6>Excellent</h6>
          <p className="p-img">
            <img
              width="50"
              height="50"
              src="https://img.icons8.com/android/50/00b67a/rating.png"
              alt="rating"
            />
            <img
              width="50"
              height="50"
              src="https://img.icons8.com/android/50/00b67a/rating.png"
              alt="rating"
            />
            <img
              width="50"
              height="50"
              src="https://img.icons8.com/android/50/00b67a/rating.png"
              alt="rating"
            />
            <img
              width="50"
              height="50"
              src="https://img.icons8.com/android/50/00b67a/rating.png"
              alt="rating"
            />
            <img
              width="50"
              height="50"
              src="https://img.icons8.com/android/50/00b67a/rating.png"
              alt="rating"
            />
          </p>
          <p>4.8 out of 5 based on 1,029 reviews</p>
          <img
            src="https://img.icons8.com/ios-glyphs/50/00b67a/star.png"
            alt="star"
            style={{ width: "20px", height: "20px" }}
          />
          <p>Trustpilot</p>
        </div>

        <div className="mainBox1">
          <div className="container-fluid">
            <div className="signpost1">
              <h1>About Us</h1>
              {/* 插入视频 */}
              <iframe
                width="100%"
                height="415"
                src="https://www.youtube.com/embed/9C4zu_GyDfU"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube video"
              ></iframe>
              <p>
                We launched Fast Pens as a modern, internet-based printed pen
                company that operates throughout the UK, Europe and the rest of
                the world.
              </p>
              <p>
                We invested heavily in our website and our easy-to-use online
                designer to enable customers to design their own pens, pencils
                and notebooks or upload completed designs. This saves lots of
                time and unlike most pen companies, it means we don't need to
                charge any setup or artwork fees!
              </p>
              <p>
                We use the latest printing and packing technology to produce and
                send our products across the UK and the rest of the world.
                Although our website is very simple to use, behind the scenes we
                use advanced systems and processes to make designing, ordering,
                printing and packing fast and easy. This enables us to despatch
                products quickly (even as fast as same-day despatch in some
                cases) and provide printing on all products.{" "}
              </p>
              <p>
                We pass the savings on to our customers, so our pricing is very
                competitive. We have no minimum order and all orders are
                despatched in just 1-3 working days.
              </p>
              <p>
                With over 120 employees and more than 40 years of printing
                experience, Fast Pens is your ideal partner for all your
                promotional pen requirements. Our wide and diverse client base
                includes hospitality, retail, personal / professional services,
                public bodies, airlines, the financial sector, travel and many
                more.
              </p>
              <p>&nbsp;</p>
              <h2>J&A Group's Social Responsibility</h2>
              <p>
                Here at the J&amp;A Group we are committed to embracing and
                implementing sustainable principles throughout our business,
                from the way we operate to the products we produce.
              </p>
              <p>
                <img
                  alt=""
                  title=""
                  src="/head/Staff-Photo-800x450.jpg"
                  width="800"
                  height="450"
                />
              </p>
            </div>
            <div className="request-cta">
              <div className="request-cta-inner">
                <img alt="" src="/head/cheese.png" />
                <div className="cta-txt">
                  Want to see physical samples of our products?
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default About;
