import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contactus.css";

function Contact() {
  const [type, setType] = useState("");
  const [forename, setForename] = useState("");
  const [surname, setSurname] = useState("");
  const [emailAdd, setEmailaddress] = useState("");
  const [telephone, setTelephone] = useState("");
  const [companyName, setCompanyname] = useState("");
  const [country, setCountry] = useState("");
  const [county, setCounty] = useState("");
  const [aboutUs, setAboutUs] = useState("");
  const [msg, setMsg] = useState("");

  const handlePlaceOrder = () => {
    const newErrors = {};
    const cardInputs = document.querySelectorAll(
      'select[name="type"], input[name="forename"], input[name="surname"],input[name="emailAdd"], input[name="telephone"], select[name="country"], select[name="county"],select[name="aboutUs"], textarea[name="msg"]'
    );
    cardInputs.forEach((input) => {
      if (
        input.tagName === "SELECT" ||
        input.tagName === "INPUT" ||
        input.tagName === "TEXTAREA"
      ) {
        if (!input.value) {
          input.style.border = "2px solid red";
        } else {
          input.style.border = "";
        }
      }
    });

    if (!type) newErrors.type = "type is required";
    if (!forename) newErrors.forename = "forename is required";
    if (!surname) newErrors.surname = "surname is required";
    if (!emailAdd) newErrors.emailAdd = "emailAdd is required";
    if (!telephone) newErrors.telephone = "telephone is required";
    if (!country) newErrors.country = "country is required";
    if (!county) newErrors.county = "county is required";
    if (!aboutUs) newErrors.aboutUs = "aboutUs is required";
    if (!msg) newErrors.msg = "msg is required";

    // setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      alert("Send successed!");
      return;
    }
  };
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="main-head">
          <p>Our customers say</p>
          <h6>Excellent</h6>
          <p className="p-img">
            <img
              width="50"
              height="50"
              src="https://img.icons8.com/android/50/00b67a/rating.png"
              alt="rating"
            />
            <img
              width="50"
              height="50"
              src="https://img.icons8.com/android/50/00b67a/rating.png"
              alt="rating"
            />
            <img
              width="50"
              height="50"
              src="https://img.icons8.com/android/50/00b67a/rating.png"
              alt="rating"
            />
            <img
              width="50"
              height="50"
              src="https://img.icons8.com/android/50/00b67a/rating.png"
              alt="rating"
            />
            <img
              width="50"
              height="50"
              src="https://img.icons8.com/android/50/00b67a/rating.png"
              alt="rating"
            />
          </p>
          <p>4.8 out of 5 based on 1,029 reviews</p>
          <img
            src="https://img.icons8.com/ios-glyphs/50/00b67a/star.png"
            alt="star"
            style={{ width: "20px", height: "20px" }}
          />
          <p>Trustpilot</p>
        </div>
        <div className="mainBox1">
          <div className="container-fluid">
            <div className="signpost1">
              <h1>Contact Us</h1>
              <div className="contact-introduction">Send us an email…</div>
              <p>
                <strong>
                  For instant results, check out our FAQs page to find answers
                  to the most common questions that our customers are asking.
                </strong>
              </p>
              <p>
                Alternatively, please complete the enquiry form below and we
                will get back to you as soon as we can. If you prefer, simply
                pick up the phone - we pride ourselves on being friendly and
                helpful... 01790 755 833
              </p>
            </div>
            <div className="contactAll">
              <div className="contactLeftBottomTitle">
                <form
                  name="form"
                  method="post"
                  id="tjs_contact_form"
                  enctype="multipart/form-data"
                >
                  <div className="form_row_container">
                    <div className="form_row is-failing">
                      <label for="form_enquiryType" className="required">
                        Enquiry type *
                      </label>
                      <select
                        id="form_enquiryType"
                        name="type"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        required="required"
                      >
                        <option value="" selected="selected">
                          Please select...
                        </option>
                        <option value="form.common.EnquiryTypeNewCustomer">
                          New Customer
                        </option>
                        <option value="form.common.EnquiryTypeExistingCustomer">
                          Existing Customer
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="form_row_container">
                    <div className="form_row is-failing">
                      <label for="form_firstName" className="required">
                        Forename *
                      </label>
                      <input
                        type="text"
                        id="form_firstName"
                        name="forename"
                        value={forename}
                        onChange={(e) => setForename(e.target.value)}
                        required="required"
                        maxlength="255"
                      />
                    </div>
                  </div>
                  <div className="form_row_container">
                    <div className="form_row">
                      <label for="form_lastName" className="required">
                        Surname *
                      </label>
                      <input
                        type="text"
                        id="form_lastName"
                        name="surname"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                        required="required"
                        maxlength="255"
                      />
                    </div>
                  </div>
                  <div className="form_row_container">
                    <div className="form_row is-failing">
                      <label for="form_email" className="required">
                        Email address *
                      </label>
                      <input
                        type="email"
                        id="form_email"
                        name="emailAdd"
                        value={emailAdd}
                        onChange={(e) => setEmailaddress(e.target.value)}
                        required="required"
                        maxlength="255"
                      />
                    </div>
                  </div>
                  <div className="form_row_container">
                    <div className="form_row">
                      <label for="form_companyName">Company name</label>
                      <input
                        type="text"
                        id="form_companyName"
                        name="companyName"
                        value={companyName}
                        onChange={(e) => setCompanyname(e.target.value)}
                        maxlength="255"
                      />
                    </div>
                  </div>
                  <div className="form_row_container">
                    <div className="form_row">
                      <label for="form_tel" className="required">
                        Telephone *
                      </label>
                      <input
                        type="tel"
                        id="form_tel"
                        name="telephone"
                        value={telephone}
                        onChange={(e) => setTelephone(e.target.value)}
                        required="required"
                        pattern="[+0-9]{10,15}"
                        maxlength="15"
                      />
                    </div>
                  </div>
                  <div className="form_row_container">
                    <div className="form_row">
                      <label for="form_country" className="required">
                        Country *
                      </label>
                      <select
                        id="form_country"
                        name="country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        required="required"
                        className="country-county-select"
                        data-countyfield="form_county"
                      >
                        <option value="">Please select...</option>{" "}
                        <option value="GB">United Kingdom</option>
                        <option disabled="disabled">-------------------</option>
                        <option value="AF">Afghanistan</option>
                        <option value="AX">Aland Islands</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AS">American Samoa</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AI">Anguilla</option>
                        <option value="AQ">Antarctica</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AW">Aruba</option>
                        <option value="AP">Asia/Pacific Region</option>
                        <option value="AU">Australia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BM">Bermuda</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO">Bolivia</option>
                        <option value="BA">Bosnia and Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BV">Bouvet Island</option>
                        <option value="BR">Brazil</option>
                        <option value="IO">
                          British Indian Ocean Territory
                        </option>
                        <option value="VG">British Virgin Islands</option>
                        <option value="BN">Brunei Darussalam</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="CV">Cape Verde</option>
                        <option value="KY">Cayman Islands</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo</option>
                        <option value="CK">Cook Islands</option>
                        <option value="CR">Costa Rica</option>
                        <option value="HR">Croatia</option>
                        <option value="CU">Cuba</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="IE">Eire</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FK">Falkland Islands</option>
                        <option value="FO">Faroe Islands</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GF">French Guiana</option>
                        <option value="PF">French Polynesia</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GI">Gibraltar</option>
                        <option value="GR">Greece</option>
                        <option value="GL">Greenland</option>
                        <option value="GD">Grenada</option>
                        <option value="GP">Guadeloupe</option>
                        <option value="GU">Guam</option>
                        <option value="GT">Guatemala</option>
                        <option value="GG">Guernsey</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="VA">
                          Holy See (Vatican City State)
                        </option>
                        <option value="HN">Honduras</option>
                        <option value="HK">Hong Kong</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IR">Iran</option>
                        <option value="IQ">Iraq</option>
                        <option value="IC">Islas Canarias</option>
                        <option value="IM">Isle of Man</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JE">Jersey</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KP">Korea</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libyan Arab Jamahiriya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MO">Macau</option>
                        <option value="MK">Macedonia</option>
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MQ">Martinique</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="YT">Mayotte</option>
                        <option value="MX">Mexico</option>
                        <option value="FM">Micronesia</option>
                        <option value="MD">Moldova</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="ME">Montenegro</option>
                        <option value="MS">Montserrat</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="AN">Netherlands Antilles</option>
                        <option value="NC">New Caledonia</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="NU">Niue</option>
                        <option value="NF">Norfolk Island</option>
                        <option value="MP">Northern Mariana Islands</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PW">Palau</option>
                        <option value="PS">Palestinian Territory</option>
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="QA">Qatar</option>
                        <option value="RE">Réunion</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russian Federation</option>
                        <option value="RW">Rwanda</option>
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="PM">Saint Pierre and Miquelon</option>
                        <option value="VC">
                          Saint Vincent and the Grenadines
                        </option>
                        <option value="WS">Samoa</option>
                        <option value="SM">San Marino</option>
                        <option value="ST">Sao Tome and Principe</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="RS">Serbia</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SZ">Swaziland</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syrian Arab Republic</option>
                        <option value="TW">Taiwan</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania</option>
                        <option value="TH">Thailand</option>
                        <option value="TG">Togo</option>
                        <option value="TK">Tokelau</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TC">Turks and Caicos Islands</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="GB" selected="selected">
                          United Kingdom
                        </option>
                        <option value="UM">
                          United States Minor Outlying Islands
                        </option>
                        <option value="US">United States of America</option>
                        <option value="VI">United States Virgin Islands</option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VE">Venezuela</option>
                        <option value="VN">Vietnam</option>
                        <option value="WF">Wallis and Futuna</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                      </select>
                    </div>
                  </div>
                  <div className="form_row_container">
                    <div className="form_row">
                      <label for="form_county" className="required">
                        County *
                      </label>
                      <select
                        id="form_county"
                        name="county"
                        value={county}
                        onChange={(e) => setCounty(e.target.value)}
                        required="required"
                        data-countyfield-holder-className="form_row"
                      >
                        <option value="" selected="selected">
                          Please select...
                        </option>
                        <option value="Aberdeenshire">Aberdeenshire</option>
                        <option value="Anglesey">Anglesey</option>
                        <option value="Angus">Angus</option>
                        <option value="Antrim">Antrim</option>
                        <option value="Ards">Ards</option>
                        <option value="Argyll">Argyll</option>
                        <option value="Armagh">Armagh</option>
                        <option value="Ayrshire">Ayrshire</option>
                        <option value="Ballymena">Ballymena</option>
                        <option value="Ballymoney">Ballymoney</option>
                        <option value="Banbridge">Banbridge</option>
                        <option value="Banffshire">Banffshire</option>
                        <option value="Bedfordshire">Bedfordshire</option>
                        <option value="Belfast">Belfast</option>
                        <option value="Berkshire">Berkshire</option>
                        <option value="Berwickshire">Berwickshire</option>
                        <option value="Blaenau Gwent">Blaenau Gwent</option>
                        <option value="Breconshire">Breconshire</option>
                        <option value="Bridgend">Bridgend</option>
                        <option value="Bristol">Bristol</option>
                        <option value="Buckinghamshire">Buckinghamshire</option>
                        <option value="Bute">Bute</option>
                        <option value="Caernarvonshire">Caernarvonshire</option>
                        <option value="Caerphilly">Caerphilly</option>
                        <option value="Caithness">Caithness</option>
                        <option value="Cambridgeshire">Cambridgeshire</option>
                        <option value="Cardiff">Cardiff</option>
                        <option value="Cardiganshire">Cardiganshire</option>
                        <option value="Carmarthenshire">Carmarthenshire</option>
                        <option value="Carrickfergus">Carrickfergus</option>
                        <option value="Castlereagh">Castlereagh</option>
                        <option value="Ceredigion">Ceredigion</option>
                        <option value="Cheshire">Cheshire</option>
                        <option value="Clackmannanshire">
                          Clackmannanshire
                        </option>
                        <option value="Clwyd">Clwyd</option>
                        <option value="Coleraine">Coleraine</option>
                        <option value="Conwy">Conwy</option>
                        <option value="Cookstown">Cookstown</option>
                        <option value="Cornwall">Cornwall</option>
                        <option value="Craigavon">Craigavon</option>
                        <option value="Cumbria">Cumbria</option>
                        <option value="Denbighshire">Denbighshire</option>
                        <option value="Derbyshire">Derbyshire</option>
                        <option value="Derry">Derry</option>
                        <option value="Devon">Devon</option>
                        <option value="Dorset">Dorset</option>
                        <option value="Down">Down</option>
                        <option value="Dumbartonshire">Dumbartonshire</option>
                        <option value="Dumfriesshire">Dumfriesshire</option>
                        <option value="Dungannon and South Tyrone">
                          Dungannon and South Tyrone
                        </option>
                        <option value="Durham">Durham</option>
                        <option value="East Lothian">East Lothian</option>
                        <option value="East Sussex">East Sussex</option>
                        <option value="East Yorkshire">East Yorkshire</option>
                        <option value="Essex">Essex</option>
                        <option value="Fermanagh">Fermanagh</option>
                        <option value="Fife">Fife</option>
                        <option value="Flintshire">Flintshire</option>
                        <option value="Glamorgan">Glamorgan</option>
                        <option value="Glasgow">Glasgow</option>
                        <option value="Gloucestershire">Gloucestershire</option>
                        <option value="Greater Manchester">
                          Greater Manchester
                        </option>
                        <option value="Gwent">Gwent</option>
                        <option value="Gwynedd">Gwynedd</option>
                        <option value="Hampshire">Hampshire</option>
                        <option value="Herefordshire">Herefordshire</option>
                        <option value="Hertfordshire">Hertfordshire</option>
                        <option value="Highland">Highland</option>
                        <option value="Inverness">Inverness</option>
                        <option value="Isle of Anglesey">
                          Isle of Anglesey
                        </option>
                        <option value="Isle of Wight">Isle of Wight</option>
                        <option value="Isles of Scilly">Isles of Scilly</option>
                        <option value="Kent">Kent</option>
                        <option value="Kincardineshire">Kincardineshire</option>
                        <option value="Kinross-shire">Kinross-shire</option>
                        <option value="Kirkcudbrightshire">
                          Kirkcudbrightshire
                        </option>
                        <option value="Lanarkshire">Lanarkshire</option>
                        <option value="Lancashire">Lancashire</option>
                        <option value="Larne">Larne</option>
                        <option value="Leicestershire">Leicestershire</option>
                        <option value="Limavady">Limavady</option>
                        <option value="Lincolnshire">Lincolnshire</option>
                        <option value="Lisburn">Lisburn</option>
                        <option value="London">London</option>
                        <option value="Londonderry">Londonderry</option>
                        <option value="Magherafelt">Magherafelt</option>
                        <option value="Merionethshire">Merionethshire</option>
                        <option value="Merseyside">Merseyside</option>
                        <option value="Merthyr Tydfil">Merthyr Tydfil</option>
                        <option value="Middlesex">Middlesex</option>
                        <option value="Midlothian">Midlothian</option>
                        <option value="Monmouthshire">Monmouthshire</option>
                        <option value="Montgomeryshire">Montgomeryshire</option>
                        <option value="Moray">Moray</option>
                        <option value="Moyle">Moyle</option>
                        <option value="Nairnshire">Nairnshire</option>
                        <option value="Neath and Port Talbot">
                          Neath and Port Talbot
                        </option>
                        <option value="Newport">Newport</option>
                        <option value="Newry and Mourne">
                          Newry and Mourne
                        </option>
                        <option value="Newtownabbey">Newtownabbey</option>
                        <option value="Norfolk">Norfolk</option>
                        <option value="North Down">North Down</option>
                        <option value="North Yorkshire">North Yorkshire</option>
                        <option value="Northamptonshire">
                          Northamptonshire
                        </option>
                        <option value="Northumberland">Northumberland</option>
                        <option value="Nottinghamshire">Nottinghamshire</option>
                        <option value="Orkney">Orkney</option>
                        <option value="Oxfordshire">Oxfordshire</option>
                        <option value="Peebleshire">Peebleshire</option>
                        <option value="Pembrokeshire">Pembrokeshire</option>
                        <option value="Perthshire">Perthshire</option>
                        <option value="Powys">Powys</option>
                        <option value="Radnorshire">Radnorshire</option>
                        <option value="Renfrewshire">Renfrewshire</option>
                        <option value="Rhondda Cynon Taff">
                          Rhondda Cynon Taff
                        </option>
                        <option value="Ross &amp; Cromarty">
                          Ross &amp; Cromarty
                        </option>
                        <option value="Roxburghshire">Roxburghshire</option>
                        <option value="Rutland">Rutland</option>
                        <option value="Selkirkshire">Selkirkshire</option>
                        <option value="Shetland">Shetland</option>
                        <option value="Shropshire">Shropshire</option>
                        <option value="Somerset">Somerset</option>
                        <option value="South Lanarkshire">
                          South Lanarkshire
                        </option>
                        <option value="South Yorkshire">South Yorkshire</option>
                        <option value="Staffordshire">Staffordshire</option>
                        <option value="Stirlingshire">Stirlingshire</option>
                        <option value="Strabane">Strabane</option>
                        <option value="Suffolk">Suffolk</option>
                        <option value="Surrey">Surrey</option>
                        <option value="Sutherland">Sutherland</option>
                        <option value="Swansea">Swansea</option>
                        <option value="Torfaen">Torfaen</option>
                        <option value="Tyne and Wear">Tyne and Wear</option>
                        <option value="Tyrone">Tyrone</option>
                        <option value="Vale of Glamorgan">
                          Vale of Glamorgan
                        </option>
                        <option value="Warwickshire">Warwickshire</option>
                        <option value="West Glamorgan">West Glamorgan</option>
                        <option value="West Lothian">West Lothian</option>
                        <option value="West Midlands">West Midlands</option>
                        <option value="West Sussex">West Sussex</option>
                        <option value="West Yorkshire">West Yorkshire</option>
                        <option value="Western Isles">Western Isles</option>
                        <option value="Wigtownshire">Wigtownshire</option>
                        <option value="Wiltshire">Wiltshire</option>
                        <option value="Worcestershire">Worcestershire</option>
                        <option value="Wrexham">Wrexham</option>
                      </select>
                    </div>
                  </div>
                  <div className="form_row_container">
                    <div className="form_row">
                      <label for="form_enquirySource" className="required">
                        How did you hear about us? *
                      </label>
                      <select
                        id="form_enquirySource"
                        name="aboutUs"
                        value={aboutUs}
                        onChange={(e) => setAboutUs(e.target.value)}
                        required="required"
                        className="otheroption-select"
                        data-otheroption-input="form_enquirySourceOther"
                      >
                        <option value="" selected="selected">
                          How did you hear about us?
                        </option>
                        <option value="google">Google</option>
                        <option value="bing">Bing</option>
                        <option value="yahoo">Yahoo</option>
                        <option value="facebook">Facebook</option>
                        <option value="twitter">Twitter</option>
                        <option value="word_of_mouth">Word of mouth</option>
                        <option value="magazine_advert">Magazine advert</option>
                        <option value="other">Other (please specify)</option>
                      </select>
                    </div>
                  </div>

                  <div className="form_row_container">
                    <div className="form_row">
                      <label for="form_enquiry">
                        Enter your message here... *
                      </label>
                      <textarea
                        id="form_enquiry"
                        name="msg"
                        value={msg}
                        onChange={(e) => setMsg(e.target.value)}
                        required="required"
                      ></textarea>
                    </div>
                  </div>

                  <div className="form_group">
                    <div className="form_submit">
                      <button
                        type="submit"
                        className="button--bee button g-recaptcha"
                        onClick={handlePlaceOrder}
                      >
                        Send enquiry
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="request-cta">
              <div className="request-cta-inner">
                <img alt="" src="/head/cheese.png" />
                <div className="cta-txt">
                  Want to see physical samples of our products?
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
